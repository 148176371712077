<template>
  <div class="container">
    <div class="mt-4" v-if="loading">
      <loader></loader>
    </div>
    <div class="row mt-4" v-else>
      <div class="col-sm-12 col-lg-8 offset-lg-2">
        <h4>{{ data.title }}</h4>
      </div>
      <div class="col-sm-12 col-lg-8 offset-lg-2 mt-2">
        <ul class="list-group mt-3" v-if="data.items">
          <router-link
            tag="li"
            :to="{ name: 'quizContent', params: { overview: $route.params.id, id: item.id } }"
            :class="{ disabled: item.status !== 3 && item.status !== 1 }"
            class="list-group-item pointer mb-3"
            v-for="item in data.items"
            :key="item.id"
          >
            <div class="row">
              <div class="col-sm-12 text-left">
                <h6>
                  <div class="d-inline-block mt-1" :style="{ color: style.quizTitleColor }">
                    {{ item.title }}
                  </div>
                  <i
                    class="material-icons mt-1 float-right"
                    v-if="item.status === 1"
                    :style="{ color: style.quizIconColor }"
                    >lock_open</i
                  >
                  <i
                    class="material-icons mt-1 float-right"
                    v-else-if="item.status === 3"
                    :style="{ color: style.quizIconColor }"
                    >check</i
                  >
                  <i class="material-icons mt-1 float-right" v-else :style="{ color: style.quizIconColor }">lock</i>
                </h6>
              </div>
            </div>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  created() {
    this.$http.get(`dashboard/testyourselves/${this.$route.params.id}`).then(
      (response) => {
        this.data = response.data;
        this.loading = false;
      },
      (err) => {
        this.$notify({
          type: 'error',
          message: this.$t('notifications.error'),
          position: 'bottom-right'
        });
        this.loading = false;
        // go back by one record, the same as history.back()
        this.$router.go(-1);
      }
    );
  },
  computed: {
    ...mapGetters('settings', ['style'])
  },
  data() {
    return {
      loading: true
    };
  }
};
</script>

<style lang="scss" scoped>
li.disabled {
  pointer-events: none;
  h6 div {
    color: #c9c9c9;
  }
}

.card-footer {
  border: none;
}
</style>
